import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React, { useEffect, useState } from 'react';

import { theme } from '@app/theme';

export function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isVisible ? (
    <button
      onClick={handleClick}
      style={{
        backgroundColor: theme.palette.secondary.main,
        border: '1px solid #ccc',
        borderRadius: 8,
        bottom: 66,
        color: theme.palette.neutral.greyDark,
        cursor: 'pointer',
        height: 40,
        padding: 0,
        position: 'fixed',
        right: 22,
        width: 44,
      }}
    >
      <ArrowUpwardIcon />
    </button>
  ) : (
    <></>
  );
}
