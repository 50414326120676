import { Box, Stack } from '@mui/material';
import { ReactElement } from 'react';

import banner from '@app/static/images/banner-01.jpg';
export function MainSlide(): ReactElement {
  return (
    <Stack
      sx={{
        backgroundColor: 'secondary.main',
        mt: 1,
      }}
    >
      <Stack
        mt={{ sm: 1.5, xs: 0 }}
        mx={1.5}
        sx={{
          '& .react-multi-carousel-dot-list': {
            bottom: '20px',
          },
          '& .react-multiple-carousel__arrow': {
            '&::before': {
              fontSize: '16px',
            },
            height: '30px',
            minHeight: '30px',
            minWidth: '30px',
            width: '30px',
          },
          pb: {
            sm: 1.5,
            xs: 0,
          },
        }}
      >
        <Box
          sx={{
            background: `url(${banner})  center / contain no-repeat`,
            borderRadius: '8px',
            height: {
              md: 400,
              sm: 275,
              xs: 256,
            },
          }}
        />
      </Stack>
    </Stack>
  );
}
