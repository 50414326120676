import { Search } from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HomeIcon from '@mui/icons-material/Home';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { Button, Grid, Modal, Stack, Typography } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { resetResponseInterceptor } from '@app/adapter/axios';
import {
  useClearAuthStateAndStorage,
  userAuthInfoState,
} from '@app/domain/app';
import { theme } from '@app/theme';
export function BottomNav(): ReactElement {
  const userInfo = useRecoilValue(userAuthInfoState);
  const { pathname } = useLocation();
  const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);
  const handleClickAuthButton = () => {
    if (userInfo) {
      setIsOpenModalLogout(true);
    }
  };

  const handleClickSearch = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    const inputSearch = document.getElementById('input-search');
    if (inputSearch) {
      inputSearch.focus();
    }
  };
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();
  const navigate = useNavigate();
  const handleClickLogout = useCallback(() => {
    clearAuthStateAndStorage();
    resetResponseInterceptor();
    setIsOpenModalLogout(false);
    navigate('/login');
  }, [clearAuthStateAndStorage, navigate, setIsOpenModalLogout]);
  return (
    <Stack
      position={'fixed'}
      left={0}
      right={0}
      bottom={0}
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: 'rgba(255, 255, 255, 0.95)' }}
    >
      <Stack
        py={1}
        direction="row"
        maxWidth="640px"
        sx={{
          justifyContent: {
            md: 'unset',
            xs: 'space-around',
          },
          opacity: 0.95,
          width: {
            md: 'unset',
            xs: '100%',
          },
        }}
      >
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <Stack
            direction={'column'}
            mx={{ md: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <HomeIcon
              sx={{
                color:
                  pathname === '/' || pathname === '/home'
                    ? 'black.main'
                    : 'neutral.greyDark',
              }}
            />
            <Typography
              sx={{
                color:
                  pathname === '/' || pathname === '/home'
                    ? 'black.main'
                    : 'neutral.greyDark',
              }}
              variant="body3"
            >
              ホーム
            </Typography>
          </Stack>
        </Link>
        <Link
          to={''}
          style={{ textDecoration: 'none' }}
          onClick={handleClickSearch}
        >
          <Stack
            direction={'column'}
            mx={{ md: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <Search sx={{ color: 'neutral.greyDark' }} />
            <Typography sx={{ color: 'neutral.greyDark' }} variant="body3">
              検索
            </Typography>
          </Stack>
        </Link>
        <Link
          to={userInfo ? '/orders' : ''}
          style={{ opacity: userInfo ? 1 : 0.3, textDecoration: 'none' }}
        >
          <Stack
            direction={'column'}
            mx={{ md: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <DescriptionOutlinedIcon
              sx={{
                color:
                  pathname === '/orders' ? 'black.main' : 'neutral.greyDark',
              }}
            />
            <Typography
              sx={{
                color:
                  pathname === '/orders' ? 'black.main' : 'neutral.greyDark',
              }}
              variant="body3"
            >
              予約履歴
            </Typography>
          </Stack>
        </Link>
        <Link
          to={userInfo ? '' : '/login'}
          style={{ textDecoration: 'none' }}
          onClick={handleClickAuthButton}
        >
          <Stack
            direction={'column'}
            mx={{ md: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <LoginOutlinedIcon
              sx={{
                color:
                  pathname === '/login' ? 'black.main' : 'neutral.greyDark',
              }}
            />
            <Typography
              sx={{
                color:
                  pathname === '/login' ? 'black.main' : 'neutral.greyDark',
              }}
              variant="body3"
            >
              {userInfo ? 'ログアウト' : 'ログイン'}
            </Typography>
          </Stack>
        </Link>
      </Stack>
      <Modal
        open={isOpenModalLogout}
        onClose={() => setIsOpenModalLogout(false)}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid
          sx={{
            backgroundColor: theme.palette.neutral.white,
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            padding: 3,
            width: '80%',
          }}
        >
          <Typography variant="body1">ログアウトしますか?</Typography>
          <Grid sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
            <Button
              variant="outlined"
              sx={{ fontSize: '0.9rem', marginRight: '10px' }}
              onClick={() => setIsOpenModalLogout(false)}
            >
              いいえ
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ fontSize: '0.9rem' }}
              onClick={handleClickLogout}
            >
              はい
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Stack>
  );
}
