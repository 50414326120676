import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import { ReactElement, useEffect, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { getCategoryTree } from '@app/adapter/catalog-service';
import { ResultItemCard } from '@app/components/Product/ResultItemCard';
import { SearchCondition } from '@app/components/Search/SearchCondition';
import { SearchNoResult } from '@app/components/Search/SearchNoResult';
import { SearchTypeTab } from '@app/components/Search/SearchTypeTab';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Loading } from '@app/components/Shared/Loading';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import { getProducts } from '@app/domain/network-actions';
import {
  categoryState,
  searchConditionState,
  searchResultProductsState,
} from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import {
  CategoryName,
  CategoryParentName,
  Product,
  SearchCondition as SearchConditionProps,
  Top,
} from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { Organization } from '@app/types/organization';
import { getSearchResultUrl } from '@app/utils/catalog';

export const orderByList = [
  {
    key: 'updatedAtDesc',
    label: '新着順',
    value: 'updatedAt desc',
  },
  {
    key: 'addressAsc',
    label: '住所順',
    value: 'locationIds,customFields.workAddress1,customFields.workAddress2',
  },
  {
    key: 'dayAsc',
    label: '日付順',
    value: 'customFields.day',
  },
];

export interface SearchResultRouteProps {
  favoriteSearch?: boolean;
}

interface VisibleProductsCount {
  [key: string]: number;
}

export function SearchResult(
  { favoriteSearch }: SearchResultRouteProps = { favoriteSearch: false }
): ReactElement {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [categoriesSharedState, setCategoriesSharedState] =
    useRecoilState(categoryState);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const [, setConditionState] = useRecoilState(searchConditionState);
  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );
  const [visibleProductsCount, setVisibleProductsCount] =
    useState<VisibleProductsCount>({});

  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );

  const getParamToString = (value: string | null) => {
    return value ? value.split(',') : undefined;
  };

  const searchCondition = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return {
      attributeIds: getParamToString(queryParams.get('attributes')) || [],
      categoryId: queryParams.get('category') || '',
      lat: queryParams.get('lat') || '',
      locationIds: getParamToString(queryParams.get('locations')) || [],
      lon: queryParams.get('lon') || '',
      orderBy: queryParams.get('orderBy') || orderByList[0].key,
      organizationId: queryParams.get('organizationId') || '',
      targetName: queryParams.get('targetName') || '',
      type: queryParams.get('type') || '',
      variant: {
        max: queryParams.get('variantMax') || '',
        min: queryParams.get('variantMin') || '',
        type: queryParams.get('variantType') || '',
      },
      work: {
        days: getParamToString(queryParams.get('days')) || [],
        timeFrom: queryParams.get('timeFrom') || '',
        timeTo: queryParams.get('timeTo') || '',
        weeks: getParamToString(queryParams.get('weeks')) || [],
      },
    };
  }, [location.search]);

  const fetchCategories = useCallback(async () => {
    if (categoriesSharedState.length) return;
    try {
      const result = await getCategoryTree();
      setCategoriesSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('タイプの取得に失敗しました');
      setErrorSnackbarOpen(true);
    }
  }, [
    categoriesSharedState,
    setCategoriesSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
  ]);

  useEffect(() => {
    void fetchCategories();
    return () => setConditionState(null);
    // eslint-disable-next-line
  }, []);

  const submitSearch = useCallback(
    (data?: SearchConditionProps) => {
      const conditions = { ...searchCondition, ...data };
      setConditionState(null);
      navigate(getSearchResultUrl(conditions));
    },
    [navigate, searchCondition, setConditionState]
  );

  const tabName = useMemo(() => {
    return (
      categoriesSharedState
        .find((v) => v.name === CategoryParentName.JOB_TYPE)
        ?.children.find((c) => c.id === searchCondition?.categoryId)?.name ||
      CategoryName.SPOT
    );
  }, [categoriesSharedState, searchCondition?.categoryId]);

  const orderByOptions = useMemo(() => {
    return orderByList.filter((o) => {
      if (tabName === CategoryName.PART_TIME) {
        return o.key !== 'dayAsc';
      }
      return true;
    });
  }, [tabName]);

  const fetchProducts = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const options = {
          ...searchCondition,
          apply:
            'groupby((organizationId), aggregate(updatedAt with max as maxProductUpdatedAt) then orderby(updatedAt desc) then top(50))',
          categoryIds: searchCondition?.categoryId
            ? [searchCondition.categoryId]
            : [],
          expand: 'variants,images,organization,category',
          nextLink,
          orderBy: 'maxProductUpdatedAt desc',
        };

        const result = await getProducts(options);

        const isOrganizationalProduct = (
          product: Product
        ): product is Product & { organization: Organization } =>
          typeof product.organization === 'object';

        const getBookmarkedPlanIndex = (
          products: Product[],
          bookmarkId: string
        ): number => products.findIndex((plan) => plan.id === bookmarkId);

        const moveBookmarkedPlanToFront = (
          products: Product[],
          bookmarkId: string
        ): void => {
          const index = getBookmarkedPlanIndex(products, bookmarkId);
          if (index > -1) {
            const [bookmarkedPlan] = products.splice(index, 1);
            products.unshift(bookmarkedPlan);
          }
        };

        const reorderProductsByBookmark = (group: Top): Top => {
          group.top.forEach((product) => {
            if (
              isOrganizationalProduct(product) &&
              product.organization.customFields.bookMarkedPlan
            ) {
              moveBookmarkedPlanToFront(
                group.top,
                product.organization.customFields.bookMarkedPlan
              );
            }
          });
          return group;
        };

        const reorderedProductGroups: Top[] = result.data.value.map(
          reorderProductsByBookmark
        );

        setConditionState(searchCondition);
        setResultProducts((prevState) => ({
          ...prevState,
          data: nextLink
            ? [...prevState.data, ...reorderedProductGroups]
            : reorderedProductGroups,
          nextLink: result.data['@nextLink'],
          total: result.data.total,
        }));
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setErrorSnackbarText('プランの取得に失敗しました');
        setErrorSnackbarOpen(true);
        setLoadable(LoadableState.HAS_ERROR);
      }
    },
    [
      loadable,
      searchCondition,
      setConditionState,
      setErrorSnackbarOpen,
      setErrorSnackbarText,
      setResultProducts,
    ]
  );

  const handleShowMore = useCallback(
    (orgId: string | number) => {
      setVisibleProductsCount((prev) => ({
        ...prev,
        [orgId]: (prev[orgId] || 1) + 3, // 3件ずつ表示
      }));
    },
    [setVisibleProductsCount]
  );

  useEffect(() => {
    void fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition, location.search]);
  const handleScrollThresholdReached = async () => {
    if (_.isEmpty(resultProducts.nextLink)) {
      return;
    }
    await fetchProducts(resultProducts.nextLink);
  };

  const onChangeTab = useCallback(
    (selectedTab: string) => {
      const categoryId =
        categoriesSharedState
          .find((v) => v.name === CategoryParentName.JOB_TYPE)
          ?.children.find((c) => c.name === selectedTab)?.id || '';

      let orderBy = searchCondition.orderBy;
      if (selectedTab === CategoryName.PART_TIME) {
        orderBy =
          orderBy === orderByList.find((v) => v.key === 'dayAsc')?.key
            ? orderByOptions[0].key
            : orderBy;
      }
      submitSearch({ ...searchCondition, categoryId, orderBy });
    },
    [categoriesSharedState, orderByOptions, searchCondition, submitSearch]
  );

  const handleClickProduct = useCallback(
    (id: string) => {
      setResultProducts({ ...resultProducts, scrollY: globalThis.pageYOffset });
      navigate(`/products/${id}`);
    },
    [navigate, resultProducts, setResultProducts]
  );

  return (
    <Box data-e2e="searchResult-body">
      <Stack spacing={1} sx={{ bgcolor: 'secondary.main' }}>
        <Box sx={{ bgcolor: 'neutral.white' }}>
          {categoriesSharedState[0] && (
            <SearchTypeTab
              items={categoriesSharedState[0].children}
              value={tabName}
              onChangeTab={onChangeTab}
            />
          )}
          <SearchCondition
            condition={searchCondition}
            onSearch={submitSearch}
          />
          <Container sx={{ py: 2 }}>
            <Stack spacing={2}>
              <Typography>{resultProducts.total}件の検索結果</Typography>
              <FormControl size="small">
                <Select
                  value={searchCondition.orderBy}
                  onChange={(e) =>
                    submitSearch({
                      ...searchCondition,
                      orderBy: e.target.value,
                    })
                  }
                >
                  {orderByOptions.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Container>
        </Box>
        {loadable !== LoadableState.LOADING &&
          resultProducts.data.length === 0 && <SearchNoResult />}
        <Stack spacing={2} sx={{ bgcolor: 'secondary.main' }}>
          {loadable === LoadableState.LOADING && (
            <Loading title={`検索結果(${resultProducts.total})`} />
          )}
          {resultProducts.data.map((group, index) => (
            <Box key={group._id} sx={{ mb: 1, mt: 1 }}>
              {group.top
                .slice(0, visibleProductsCount[group.organizationId] || 1)
                .map((product, productIndex, productArray) => (
                  <Box
                    key={product.id}
                    sx={{
                      my: productIndex === productArray.length - 1 ? 0 : 1, // 最後のアイテムだけ余白を0に設定
                    }}
                  >
                    <ResultItemCard
                      product={product}
                      onClick={() => handleClickProduct(product.id)}
                      favoriteSearch={favoriteSearch}
                      showOrganizationInfo={productIndex === 0}
                    />
                  </Box>
                ))}
              <Box
                sx={{
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2,
                  pb: 2,
                }}
              >
                {group.top.length >
                  (visibleProductsCount[group.organizationId] || 1) && (
                  <Button
                    variant="text"
                    sx={{
                      ':hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.text.primary,
                        textDecoration: 'underline',
                      },
                      border: 'none',
                      color: theme.palette.text.primary,
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }}
                    onClick={() => handleShowMore(group.organizationId)}
                  >
                    もっとプランを見る
                  </Button>
                )}
              </Box>
            </Box>
          ))}
        </Stack>
        <ScrollThreshold
          disabled={_.isEmpty(resultProducts.nextLink)}
          thresholdReached={handleScrollThresholdReached}
        />
      </Stack>
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </Box>
  );
}
