import {
  Menu as MenuIcon,
  Article as ArticleIcon,
  MailOutline as MailOutlineIcon,
  /* NotificationsNone as NotificationsNoneIcon, */
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Snackbar,
  Alert,
  Badge,
  styled,
  Typography,
  Hidden,
} from '@mui/material';
import React, {
  ReactElement,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SearchBox } from '../Search/SearchBox';

import { SideMenu } from './SideMenu';

import { getAllMessagesToOneUser } from '@app/adapter/chat-service';
import { userAuthInfoSelector } from '@app/domain/app';
import { totalUnreadCountState } from '@app/domain/chat';
import { getUserOrders } from '@app/domain/network-actions';
import {
  navPortalContainerRef,
  navPortalSpacerRef,
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
  errorSnackbarOpenState,
  errorSnackbarTextState,
  errorSnackbarSeverityState,
  sidebarOpenState,
  orderCountState,
} from '@app/domain/top-nav';
import { imageAssets } from '@app/theme';
import { MessageCategory, MessageReadStatus } from '@app/types/chat';
import { OrderStatus } from '@app/types/order';
import { isError } from '@app/utils/error';

const IconBadgeButton = styled(IconButton)(() => ({
  '& .MuiBadge-anchorOriginTopRightRectangle': {
    display: 'none',
  },
  '& .MuiBadge-badge': {
    fontSize: '10px',
    height: '18px',
    maxWidth: '18px',
    minWidth: '18px',
  },
  padding: '8px',
}));

export function TopNav(): ReactElement {
  const [isSnackbarOpen, setIsSnackbarOpen] = useRecoilState(snackbarOpenState);
  const [snackbarText, setSnackbarText] = useRecoilState(snackbarTextState);
  const [snackbarSeverity, setSnackbarSeverity] = useRecoilState(
    snackbarSeverityState
  );
  const [shouldErrorSnackbarOpen, setShouldErrorSnackbarOpen] = useRecoilState(
    errorSnackbarOpenState
  );
  const [errorSnackbarText, setErrorSnackbarText] = useRecoilState(
    errorSnackbarTextState
  );
  const [errorSnackbarSeverity, setErrorSnackbarSeverity] = useRecoilState(
    errorSnackbarSeverityState
  );
  const [orderCount, setOrderCount] = useRecoilState(orderCountState);
  const [unreadCount, setUnreadCount] = useRecoilState(totalUnreadCountState);
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const navigate = useNavigate();

  const [portalContainer, setPortalContainer] = useRecoilState(
    navPortalContainerRef
  );
  const portalContainerRef = useRef(null);
  useEffect(() => {
    if (portalContainer?.current) {
      return;
    }
    setPortalContainer(portalContainerRef);
  }, [portalContainerRef, portalContainer, setPortalContainer]);

  const portalSpacerRef = useRef(null);
  const [portalSpacer, setPortalSpacer] = useRecoilState(navPortalSpacerRef);
  useEffect(() => {
    if (portalSpacer?.current) {
      return;
    }
    setPortalSpacer(portalSpacerRef);
  }, [portalSpacerRef, portalSpacer, setPortalSpacer]);

  const fetchMessageUnreadCount = useCallback(async () => {
    if (!authInfo) return;
    try {
      const result = await getAllMessagesToOneUser(
        authInfo.accessToken,
        authInfo.fingerPrint,
        authInfo.userId,
        {
          category: MessageCategory.SUPPLY,
          limit: 100,
          readStatus: MessageReadStatus.UNREAD,
        }
      );
      setUnreadCount(result.data.count);
    } catch (error) {
      if (isError(error)) {
        console.error('fetchMessagesAndUnreadCount', error.message);
      }
    }
  }, [authInfo, setUnreadCount]);

  const fetchOrderCount = useCallback(async () => {
    if (!authInfo) return;
    try {
      const response = await getUserOrders(
        authInfo.accessToken,
        authInfo.userId,
        {
          limit: 100,
          statuses: [OrderStatus.ACCEPTED, OrderStatus.PROCESSING],
        }
      );

      const ordersWithProduct = response.data.value.filter((order) =>
        order.lineItems.every(
          (item) => item.product !== null && typeof item.product !== 'string'
        )
      );

      setOrderCount(ordersWithProduct.length);
    } catch (error) {
      if (isError(error)) {
        console.error('fetchOrderCount', error.message);
      }
    }
  }, [authInfo, setOrderCount]);

  useEffect(() => {
    if (!authInfo) return;
    async function updateCount() {
      void fetchOrderCount();
      void fetchMessageUnreadCount();
    }
    void updateCount();

    const intervalId = setInterval(() => {
      void updateCount();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo]);

  const replaceMaxBadge = (badgeContent = 0) => {
    return badgeContent > 99 ? 99 : badgeContent;
  };

  const [shouldSidebarOpen, setShouldSidebarOpen] =
    useRecoilState(sidebarOpenState);
  const toggleSideMenu = useCallback(
    (event: KeyboardEvent | MouseEvent) => {
      setShouldSidebarOpen((open) => !open);
    },
    [setShouldSidebarOpen]
  );

  useEffect(() => {
    if (!shouldErrorSnackbarOpen) {
      setErrorSnackbarText('');
      setErrorSnackbarSeverity('error');
    }
  }, [shouldErrorSnackbarOpen, setErrorSnackbarText, setErrorSnackbarSeverity]);

  useEffect(() => {
    if (!isSnackbarOpen) {
      setSnackbarText('');
      setSnackbarSeverity('error');
    }
  }, [isSnackbarOpen, setSnackbarText, setSnackbarSeverity]);

  return (
    <>
      <AppBar color="primary" sx={{ boxShadow: 'none' }}>
        <Toolbar sx={{ minHeight: '64px' }}>
          <Box
            display="flex"
            alignItems="center"
            onClick={() => navigate('/home')}
          >
            <img
              src={imageAssets.logo}
              alt="Logo"
              style={{ height: 'auto', width: '100px' }}
            />
          </Box>

          <Box flexGrow="1" />
          <Hidden smDown>
            <SearchBox />
          </Hidden>
          {authInfo?.userId && (
            <>
              <IconBadgeButton
                size="large"
                aria-label="予約"
                color="black"
                sx={{ ':hover': { borderRadius: { md: '8px' } } }}
                onClick={() => navigate('/orders')}
              >
                <Badge
                  badgeContent={replaceMaxBadge(orderCount)}
                  color="error"
                  max={99}
                  overlap="circular"
                >
                  <ArticleIcon />
                </Badge>
                <Typography
                  variant="body2"
                  sx={{ display: { md: 'block', xs: 'none' }, ml: 1 }}
                >
                  予約一覧
                </Typography>
              </IconBadgeButton>
              <IconBadgeButton
                size="large"
                aria-label="メッセージ"
                color="black"
                onClick={() => navigate('/chats')}
              >
                <Badge
                  badgeContent={replaceMaxBadge(unreadCount)}
                  color="error"
                  max={99}
                  overlap="circular"
                >
                  <MailOutlineIcon />
                </Badge>
                <Typography
                  variant="body2"
                  sx={{ display: { md: 'block', xs: 'none' }, ml: 1 }}
                >
                  メッセージ
                </Typography>
              </IconBadgeButton>
              {/* 一時的に非表示
              <IconBadgeButton
                size="large"
                aria-label="お知らせ"
                color="black"
                onClick={() => navigate('/')}
              >
                <Badge
                  badgeContent={replaceMaxBadge(0)}
                  color="error"
                  max={99}
                  overlap="circular"
                >
                  <NotificationsNoneIcon />
                </Badge>
              </IconBadgeButton>
              */}
            </>
          )}
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            color="black"
            sx={{ ml: '2px', mr: -2 }}
            onClick={toggleSideMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Box ref={portalContainerRef} />
        <Snackbar
          open={shouldErrorSnackbarOpen}
          autoHideDuration={4000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={() => {
            setShouldErrorSnackbarOpen(false);
          }}
          data-e2e="snackBar"
        >
          <Alert
            onClose={() => {
              setShouldErrorSnackbarOpen(false);
            }}
            severity={errorSnackbarSeverity}
            sx={{ width: '100%' }}
          >
            {errorSnackbarText}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={4000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={() => setIsSnackbarOpen(false)}
          data-e2e="snackBar"
        >
          <Alert
            onClose={() => setIsSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarText}
          </Alert>
        </Snackbar>
      </AppBar>
      <Toolbar sx={{ mb: 2, minHeight: '64px' }} />
      <Hidden smUp>
        <SearchBox sx={{ mx: 1.5 }} />
      </Hidden>
      <Box ref={portalSpacerRef} />
      <SideMenu
        open={shouldSidebarOpen}
        anchor="right"
        onClose={toggleSideMenu}
        onOpen={toggleSideMenu}
      />
    </>
  );
}
