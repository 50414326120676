import { Search } from '@mui/icons-material';
import { Stack, OutlinedInput, SxProps, Theme } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface SearchBoxProps {
  sx?: SxProps<Theme>;
}
export function SearchBox(props: SearchBoxProps) {
  const { sx } = props;
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<{ targetName: string }>({
    defaultValues: { targetName: '' },
    mode: 'onChange',
  });
  const onSubmitSearch = (data: { targetName: string }) => {
    navigate(`/search/services?targetName=${data.targetName}`);
  };
  return (
    <form onSubmit={handleSubmit(onSubmitSearch)}>
      <Stack direction="row" spacing={1} alignItems="center" sx={sx}>
        <Controller
          name="targetName"
          control={control}
          render={({ field }) => (
            <OutlinedInput
              id="input-search"
              {...field}
              placeholder="店名・ジャンル・エリア"
              color="primary"
              fullWidth
              inputProps={{
                sx: { p: 1 },
              }}
              sx={{
                backgroundColor: 'secondary.main',
                borderRadius: 1,
              }}
              endAdornment={
                <Search
                  sx={{ cursor: 'pointer' }}
                  onClick={handleSubmit(onSubmitSearch)}
                />
              }
            />
          )}
        />
      </Stack>
    </form>
  );
}
