import { Container, Stack, Typography } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  getAttributes,
  getCategoryTree,
  getLocationTree,
  getProductsByType,
} from '@app/adapter/catalog-service';
import { HomeCarousel } from '@app/components/Home/HomeCarousel';
import {
  ConditionProps as DateTimeConditionProps,
  DateTimeModal,
} from '@app/components/Search/DateTimeModal';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Loading } from '@app/components/Shared/Loading';
import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';
import { userAuthInfoSelector } from '@app/domain/app';
import {
  categoryState,
  locationRegionsState,
  pickupAttributesState,
  searchConditionState,
} from '@app/domain/search';
import {
  errorSnackbarOpenState,
  errorSnackbarTextState,
} from '@app/domain/top-nav';
import { MainSlide } from '@app/routes/MainSlide';
import { ProductSlide } from '@app/routes/ProductSlide';
import { TopMenu } from '@app/routes/TopMenu';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import calendar from '@app/static/images/icon-calendar.svg';
import dish from '@app/static/images/icon-dish.svg';
import {
  CategoryName,
  CategoryParentName,
  Product,
  ProductLocationType,
} from '@app/types/catalog';
import { getSearchResultUrl } from '@app/utils/catalog';
import { isError } from '@app/utils/error';
interface LocationCoords {
  lat: number;
  lon: number;
}

export function Home(): ReactElement {
  const navigate = useNavigate();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(errorSnackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(errorSnackbarTextState);
  const setConditionState = useSetRecoilState(searchConditionState);
  const [categoriesSharedState, setCategoriesSharedState] =
    useRecoilState(categoryState);
  const [isDateTimeModal, setIsDateTimeModal] = useState(false);
  const [tab] = useState<string>(CategoryName.SPOT);
  const [pickupAttributesSharedState, setPickupAttributesSharedState] =
    useRecoilState(pickupAttributesState);
  const [regionsSharedState, setRegionsSharedState] =
    useRecoilState(locationRegionsState);
  const [isLoading, setIsLoading] = useState(true);
  const [currentLatLon, setCurrentLatLon] = useState<{
    lat: number;
    lon: number;
  }>({ lat: 0, lon: 0 });
  const [newProduct, setNewProduct] = useState<Product[]>([]);
  const [popularProduct, setPopularProduct] = useState<Product[]>([]);
  const [nearbyProduct, setNearbyProduct] = useState<Product[]>([]);

  // すべてのfetch処理が終了したらisLoadingをfalseに
  const checkAllFetchesCompleted = useCallback(() => {
    if (
      !categoriesSharedState.length ||
      !pickupAttributesSharedState.length ||
      !regionsSharedState.length
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    categoriesSharedState.length,
    pickupAttributesSharedState.length,
    regionsSharedState.length,
  ]);
  const tabId = useMemo(() => {
    return (
      categoriesSharedState
        .find((v) => v.name === CategoryParentName.JOB_TYPE)
        ?.children.find((c) => c.name === tab)?.id || ''
    );
  }, [categoriesSharedState, tab]);

  const fetchLocations = useCallback(async () => {
    if (regionsSharedState.length) {
      checkAllFetchesCompleted();
      return;
    }
    setIsLoading(true);
    try {
      const result = await getLocationTree({
        type: ProductLocationType.REGION,
      });
      setRegionsSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('エリアの取得に失敗しました');
      setErrorSnackbarOpen(true);
    } finally {
      checkAllFetchesCompleted();
    }
  }, [
    setRegionsSharedState,
    regionsSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
    checkAllFetchesCompleted,
  ]);

  const fetchCategories = useCallback(async () => {
    if (categoriesSharedState.length) {
      checkAllFetchesCompleted();
      return;
    }
    setIsLoading(true);
    try {
      const result = await getCategoryTree();
      setCategoriesSharedState(result.data.value);
    } catch (err) {
      setErrorSnackbarText('ジャンルの取得に失敗しました');
      setErrorSnackbarOpen(true);
    } finally {
      checkAllFetchesCompleted();
    }
  }, [
    setCategoriesSharedState,
    categoriesSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
    checkAllFetchesCompleted,
  ]);

  const fetchAttributes = useCallback(async () => {
    if (pickupAttributesSharedState.length) {
      checkAllFetchesCompleted();
      return;
    }
    setIsLoading(true);
    // ピックアップするジャンルをここで指定
    const genresToFetch = [
      '寿司・和食',
      '居酒屋',
      '食べ放題',
      '焼肉',
      'ビストロ',
      'アジア・エスニック',
      'のれん街・横丁',
    ];
    try {
      const genreResult = await getAttributes({ names: genresToFetch });
      setPickupAttributesSharedState(genreResult.data.value);
    } catch (err) {
      setErrorSnackbarText('ジャンルの取得に失敗しました');
      setErrorSnackbarOpen(true);
    } finally {
      checkAllFetchesCompleted();
    }
  }, [
    setPickupAttributesSharedState,
    pickupAttributesSharedState,
    setErrorSnackbarText,
    setErrorSnackbarOpen,
    checkAllFetchesCompleted,
  ]);

  const getLocation = () => {
    return new Promise<LocationCoords>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lon: longitude });
          },
          (error) => {
            reject(error.message);
          }
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  };

  const fetchProducts = useCallback(async () => {
    try {
      const [newProductResponse, popularProductResponse] = await Promise.all([
        getProductsByType({ type: 'new' }),
        getProductsByType({ type: 'popular' }),
      ]);

      setNewProduct(newProductResponse.data.value);
      setPopularProduct(popularProductResponse.data.value);
    } catch (error) {
      if (isError(error)) {
        setErrorSnackbarText(error.message);
        setErrorSnackbarOpen(true);
      }
    }
  }, [setErrorSnackbarOpen, setErrorSnackbarText]);

  useEffect(() => {
    void (async () => {
      let coords = null;
      try {
        coords = await getLocation();
      } catch (e) {
        console.error(e);
      }

      const nearbyProductResponse = await getProductsByType({
        lat: String(coords?.lat || ''),
        lon: String(coords?.lon || ''),
        type: 'nearby',
      });
      setNearbyProduct(nearbyProductResponse.data.value);
      coords && setCurrentLatLon(coords);
    })();
  }, []);

  useEffect(() => {
    void fetchLocations();
    void fetchCategories();
    void fetchAttributes();
  }, [fetchLocations, fetchCategories, fetchAttributes]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  const searchDateTime = useCallback(
    (conditions: DateTimeConditionProps) => {
      setConditionState(null);
      navigate(
        getSearchResultUrl({
          categoryId: tabId,
          work: {
            days: tab === CategoryName.SPOT ? conditions.days : [],
            timeFrom: conditions.timeFrom,
            timeTo: conditions.timeTo,
            weeks: conditions.weeks,
          },
        })
      );
    },
    [navigate, setConditionState, tab, tabId]
  );

  return (
    <>
      <TopNavPortal />
      {!authInfo && <HomeCarousel />}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <TopMenu />
          <MainSlide />
          <ProductSlide
            onClickAll={() => navigate(`/search/services?type=new`)}
            title="新着のお店"
            products={newProduct}
          />
          <ProductSlide
            onClickAll={() => navigate(`/search/services?type=popular`)}
            title="人気のお店"
            products={popularProduct}
          />
          <ProductSlide
            onClickAll={() =>
              navigate(
                `/search/services?type=nearby&lat=${currentLatLon.lat}&lon=${currentLatLon.lon}`
              )
            }
            title="近くのお店"
            products={nearbyProduct}
          />
          <Container
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              my: 3,
            }}
          >
            <Stack
              direction="row"
              justifyContent={{ md: 'center', xs: 'space-between' }}
              width="100%"
            >
              <Stack
                sx={{
                  '&:hover': {
                    borderColor: 'text.primary',
                  },
                  alignItems: 'center',
                  backgroundColor: 'secondary.main',
                  border: '1px solid #d5cfbb',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  mr: { md: 5 },
                  padding: {
                    md: '20px',
                    xs: '10px',
                  },
                  transition: 'all .2s ease-in-out',
                  width: {
                    md: '300px',
                    xs: '48%',
                  },
                }}
                onClick={() => setIsDateTimeModal(true)}
              >
                <img src={calendar} width="45" height="45" alt="calender" />
                <Typography variant="body1" sx={{ mt: 1 }}>
                  日時から探す
                </Typography>
              </Stack>
              <Stack
                sx={{
                  '&:hover': {
                    borderColor: 'text.primary',
                  },
                  alignItems: 'center',
                  backgroundColor: 'secondary.main',
                  border: '1px solid #d5cfbb',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',

                  padding: {
                    md: '20px',
                    xs: '10px',
                  },
                  transition: 'all .2s ease-in-out',
                  width: {
                    md: '300px',
                    xs: '48%',
                  },
                }}
                onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
              >
                <img src={dish} width="45" height="45" alt="dish" />
                <Typography variant="body1" sx={{ mt: 1 }}>
                  各ジャンルから探す
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </>
      )}
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
      <DateTimeModal
        category={tab}
        open={isDateTimeModal}
        onClose={() => setIsDateTimeModal(false)}
        onClickSearch={searchDateTime}
      />
    </>
  );
}
