import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';

import { useDeviceType } from '@app/hooks/useBrowserHooks';
import defaultImage from '@app/static/images/defaultImage.png';
import iconOffTag from '@app/static/images/iconOffTag.svg';
import { theme } from '@app/theme';
import { Product, ProductImage } from '@app/types/catalog';
import { Organization } from '@app/types/organization';
interface ProductSlideProps {
  onClickAll: () => void;
  products: Product[];
  title: string;
}

export function ProductSlide({
  products,
  title,
}: ProductSlideProps): ReactElement {
  const { isMobile, isDesktop } = useDeviceType();
  const navigate = useNavigate();
  return (
    <Stack
      mt={1.5}
      ml={0.3}
      mr={1.5}
      maxWidth={{ md: '1260px' }}
      mx={{ md: 'auto' }}
      sx={{
        '& .react-multiple-carousel__arrow': {
          '&::before': {
            fontSize: '16px',
          },
          height: '30px',
          minHeight: '30px',
          minWidth: '30px',
          width: '30px',
        },
      }}
    >
      <Stack
        ml={1.5}
        mb={2}
        mt={2}
        direction="row"
        justifyContent="space-between"
      >
        <Typography component={'h2'} fontWeight="bold" fontSize="1.25rem">
          {title}
        </Typography>
      </Stack>
      <Carousel
        swipeable
        arrows={isDesktop}
        autoPlay
        autoPlaySpeed={4000}
        centerMode={false}
        focusOnSelect={false}
        infinite
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        pauseOnHover={false}
        responsive={{
          desktop: {
            breakpoint: { max: 3000, min: 900 },
            items: 4,
          },
          mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1.5,
          },
          tablet: {
            breakpoint: { max: 899, min: 601 },
            items: 3,
          },
        }}
        slidesToSlide={1}
      >
        {products.map((v, i) => (
          <Box
            key={i}
            px={1}
            overflow="hidden"
            sx={{
              position: 'relative',
            }}
          >
            <Stack
              component="div"
              onClick={() => navigate(`/products/${v.id}`)}
              sx={{
                '& img': {
                  transition: 'transform 0.4s ease-in-out',
                },
                '&:hover': {
                  '& img': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.4s ease-in-out',
                  },
                },
                border: `1px solid ${theme.palette.neutral.greyDark}`,
                borderRadius: 2,
                cursor: 'pointer',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{ overflow: 'hidden', position: 'relative' }}
                height={isMobile ? 180 : 250}
              >
                <img
                  src={
                    (v.images.slice(-1)[0] as ProductImage)?.url ||
                    (v.organization as Organization)?.iconImageUri ||
                    defaultImage
                  }
                  alt="product"
                  height={isMobile ? 180 : 250}
                  width={'100%'}
                  style={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    objectFit: 'cover',
                  }}
                />
                {!!v.customFields.originPrice && v.customFields.price && (
                  <Stack
                    direction="row"
                    sx={{
                      '&::after': {
                        background: `url(${iconOffTag}) center / cover no-repeat`,
                        content: '""',
                        display: 'block',
                        height: '100%',
                        position: 'absolute',
                        right: '-8px',
                        top: 0,
                        width: '8px',
                      },
                      backgroundColor: 'black.main',
                      bottom: 12,
                      color: 'white',
                      left: 0,
                      pl: 1.2,
                      position: 'absolute',
                      pr: 0.8,
                      py: 0.4,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="primary.main"
                      fontWeight="bold"
                    >
                      {Math.ceil(
                        ((Number(v.customFields.originPrice) -
                          Number(v.customFields.price)) /
                          Number(v.customFields.originPrice)) *
                          100
                      )}
                      %
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.white"
                      fontWeight="bold"
                    >
                      OFF
                    </Typography>
                  </Stack>
                )}
              </Box>
              <Stack
                p={1.5}
                sx={{
                  borderRadius: 2,
                  borderTopWidth: 0,
                  minHeight: '180px',
                  mt: '-4px',
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{
                    '&:hover': {
                      opacity: 0.8,
                    },
                    color: 'text.primary',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    transition: 'opacity 0.2s ease-in-out',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {v.name}
                </Typography>
                <Typography
                  component="span"
                  variant="subtitle1"
                  sx={{
                    color: 'text.primary',
                    mt: 0.5,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {v.description}
                </Typography>
                {!!v.customFields.originPrice && (
                  <Stack direction="row" mt={2}>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        border: '1px solid',
                        borderRadius: 0.8,
                        px: 0.5,
                        py: 0.1,
                      }}
                    >
                      通常
                    </Typography>
                    <Typography variant="body2" component="span" ml={1.6}>
                      {v.customFields.originPrice}円
                    </Typography>
                  </Stack>
                )}
                {!!v.customFields.originPrice && !!v.customFields.price && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    mt={1}
                    width="86px"
                    m={0}
                  >
                    <Box />
                    <ArrowDropDownIcon fontSize="medium" />
                  </Stack>
                )}
                {!!v.customFields.price && (
                  <Stack direction="row">
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        border: '1px solid',
                        borderRadius: 0.8,
                        px: 0.5,
                        py: 0.1,
                      }}
                    >
                      税込
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: 'error.main', fontWeight: 'bold' }}
                      component="span"
                      ml={1.6}
                    >
                      {v.customFields.price}円
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Box>
        ))}
      </Carousel>
    </Stack>
  );
}
