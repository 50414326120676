import { Avatar, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { pickupAttributesState } from '@app/domain/search';
import category1 from '@app/static/images/category/category1.jpg';
import category2 from '@app/static/images/category/category2.jpg';
import category3 from '@app/static/images/category/category3.jpg';
import category4 from '@app/static/images/category/category4.jpg';
import category5 from '@app/static/images/category/category5.jpg';
import category6 from '@app/static/images/category/category6.jpg';
import iconDot from '@app/static/images/icon-dot.svg';
import { getSearchResultUrl } from '@app/utils/catalog';
export function TopMenu(): ReactElement {
  const navigate = useNavigate();
  const [pickupAttributesSharedState] = useRecoilState(pickupAttributesState);
  const categoryIcon = [
    category1,
    category2,
    category3,
    category4,
    category5,
    category6,
  ];
  return (
    <Stack maxWidth={{ md: '1260px' }} mx={{ md: 'auto' }}>
      <Stack
        p={1.5}
        pb={0}
        sx={{ overflowX: 'auto', overflowY: 'hidden' }}
        direction="row"
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          mr={1.5}
          mb={1}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(
              getSearchResultUrl({
                attributeIds: pickupAttributesSharedState.map((v) => v.id),
              })
            );
          }}
        >
          <Avatar
            variant="circular"
            sx={{
              '&:hover': {
                opacity: 0.8,
              },
              bgcolor: 'secondary.main',
              height: 70,
              width: 70,
            }}
          >
            <img
              src={iconDot}
              alt="all-categories"
              style={{ color: 'primary.main' }}
              width="26"
              height="26"
            />
          </Avatar>
          <Typography>すべて</Typography>
        </Stack>
        {pickupAttributesSharedState.map((v, i) => (
          <Stack
            key={i}
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            mr={1.5}
            mb={1}
            onClick={() => {
              navigate(
                getSearchResultUrl({
                  attributeIds: [v.id],
                })
              );
            }}
            sx={{
              '& img': {
                transition: 'transform 0.2s ease-in-out',
              },
              '&:hover': {
                '& img': {
                  transform: 'scale(1.1)',
                  transition: 'transform 0.2s ease-in-out',
                },
              },
              cursor: 'pointer',
              width: '80px',
            }}
          >
            <Avatar
              variant="circular"
              sx={{
                bgcolor: 'secondary.main',
                height: 70,
                width: 70,
              }}
            >
              <img
                src={categoryIcon[i]}
                alt="all-categories"
                style={{ color: 'primary.main' }}
              />
            </Avatar>
            <Typography>{v.name}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
