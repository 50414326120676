import { Box } from '@mui/material';
import { ReactElement, useEffect } from 'react';

import { ScrollToTopButton } from '../Shared/ScrollToTopButton';

import { Loading } from '@app/components/Shared/Loading';
import { TopNav } from '@app/components/TopNav';
import {
  TOKEN_VERIFY_STATUS,
  useVerifyStoredAccessToken,
} from '@app/domain/app';
import { BottomNav } from '@app/routes/BottomNav';
import { chatClient } from '@app/socket/chat-socket';

interface DefaultLayoutProps {
  children: ReactElement;
}

export function DefaultLayout({ children }: DefaultLayoutProps): ReactElement {
  useEffect(() => {
    return () => {
      // FIXME: To remove this after we have logout / session management
      // To disconnect the chatClient when component unmounts
      chatClient.disconnect();
    };
  }, []);

  // ログイン状態検証
  const verifyState = useVerifyStoredAccessToken();

  return verifyState === TOKEN_VERIFY_STATUS.PROGRESS ? (
    <Loading />
  ) : (
    <>
      <TopNav />
      <Box component="main">{children}</Box>
      <BottomNav />
      <ScrollToTopButton />
    </>
  );
}
